@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap');
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

html,
body {
  font-family: 'DM Sans', sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@layer base {
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26.04px;
  }

  .body-20 {
    font-size: 20px;
    line-height: 110%;
  }

  .body-18 {
    font-size: 18px;
    line-height: 160%;
  }

  .body-16 {
    font-size: 16px;
    line-height: 21px;
  }

  .body-14 {
    font-size: 14px;
    line-height: 160%;
  }

  .body-12 {
    font-size: 12px;
    line-height: 100%;
  }

  .body-10 {
    font-size: 10px;
    line-height: 100%;
  }
}

@layer components {
  .primary-button {
    @apply bg-purple text-white rounded-full py-2 px-6 body-14 font-medium;
  }
}


:root {
  --account-center-border: white;
  --account-center-box-shadow: 0;
  --account-center-border-radius: 2px;
}

ngneat-dialog {

  @media screen and (max-width: 767px) {
    .ngneat-dialog-backdrop {
      padding: 0 !important;
    }

    --dialog-content-border-radius: 20px 20px 0 0;
  }

  .ngneat-dialog-content {
    overflow: auto;
  }
}
